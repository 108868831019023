@font-face {
  font-family: font;
  src: url("./assets/font/ChakraPetch-Bold.ttf");
}
body {
  font-family: font, sans-serif;
  background-color: #0e0114;
  font-size: 20px;
  line-height: 25px;
  color: #fff;
}

.App {
  min-height: 100vh;
  overflow: hidden;
  background-image: url("./assets/image/bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.rec {
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
}

.rec-content {
  position: absolute;
  top: -50px;
  left: 0;
  right: 0;
  text-align: center;
}

.rec-title {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
}

.rec-desc {
  max-width: 1000px;
  margin: 0 auto;
  padding: 0 50px;
}

.rec-title img {
  width: 100%;
}

.rec-img {
  height: 400px;
}

.logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  bottom: 0;
  height: 100vh;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  max-width: 1200px;
  height: 100%;
  position: absolute;
  bottom: 0;
}

.socials {
  z-index: 10;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 20%;
  display: flex;
  gap: 50px;
}

.btn-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: #000;
  border-radius: 50px;
  transition: all 0.3s linear;
}

.btn-wrapper:hover {
  opacity: 0.8;
}

.btn-wrapper:hover .btn {
  background-color: #cf2c5a;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  color: #000;
  width: 220px;
  height: 65px;
  border-radius: 35px;
  margin-left: 5px;
}

.btn-wrapper img {
  width: 50px;
  height: 44px;
}

.support {
  position: absolute;
  bottom: 5%;
  left: 50%;
  z-index: 10;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: #fff;
  border: 6px solid #000;
  color: #000;
  padding: 10px 50px;
  border-radius: 50px;
}

.support-list {
  display: flex;
  gap: 20px;
}

.support-img {
  width: 50px;
}

.support-img img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .logo {
    width: 100%;
  }
  .socials {
    flex-direction: column;
    gap: 20px;
  }
  body {
    font-size: 18px;
  }
  .logo img {
    width: 500px;
    height: auto;
  }
  .btn-wrapper img {
    width: 35px;
    height: 28px;
  }
  .btn {
    width: 200px;
    height: 55px;
  }
  .rec-title,
  .rec-desc {
    padding: 0 20px;
  }
  .support {
    width: 200px;
    padding: 10px 30px;
  }
  .support-img {
    width: 30px;
  }
  .rec {
    top: 45%;
  }
}
